'use client';

import { useEffect, useState } from 'react';
import Badge from '@/components/ui/Badge';
import { useLocale, useTranslations } from '@/hooks';
import { useAffiliateStore, useDiscountStore } from '@/store';
import { cn, formatCurrency } from '@/utils';
export default function PaymentDetails({
  billing,
  creditsScoreUsed,
  discountPercent,
  totalDiscountAmount,
  disableAffiliate,
  price,
  children,
  className
}) {
  const [totalDiscountedAmount, setTotalDiscountedAmount] = useState(0);
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  useEffect(() => {
    if (discountPercent) {
      setTotalDiscountedAmount(price * (discountPercent / 100));
    }
  }, [discountPercent, price]);
  useEffect(() => {
    if (totalDiscountAmount) {
      setTotalDiscountedAmount(totalDiscountAmount);
    }
  }, [totalDiscountAmount, price]);
  const {
    affiliate
  } = useAffiliateStore();
  const {
    discount,
    canUseDiscount,
    discountAmount
  } = useDiscountStore();
  const classes = cn({
    'payment-details': true,
    [className]: className
  });
  return <ul className={classes} data-sentry-component="PaymentDetails" data-sentry-source-file="PaymentDetails.jsx">
      {billing && <li>
          <label>
            {billing === 'one-time' && t('payment.common.base_price')}
            {billing === 'monthly' && t('payment.disclaimer.yearly_subscription')}
          </label>
          <span>{formatCurrency(price, locale)}</span>
        </li>}

      {!billing && price > 0 && <li>
          <label>{t('payment.common.base_price')}</label>
          <span>{formatCurrency(price + totalDiscountedAmount, locale)}</span>
        </li>}

      {totalDiscountedAmount > 0 && <li>
          <label>{t('payment.common.discount', {
          entity: 'discount'
        })}</label>
          <div className="flex items-center gap-2">
            {discountPercent && <Badge variant="green-light" label={`-${parseInt(discountPercent)}%`} />}
            <span>
              {'- '}
              {formatCurrency(totalDiscountedAmount, locale)}
            </span>
          </div>
        </li>}

      {creditsScoreUsed > 0 && <li>
          <label>{t('payment.common.discount', {
          entity: 'credits'
        })}</label>
          <span>
            {'- '}
            {formatCurrency(creditsScoreUsed, locale)}
          </span>
        </li>}

      {!(discount && canUseDiscount) && affiliate?.discountPercentage > 0 && !disableAffiliate && <li>
            <label className="m-0">{t('payment.common.discount', {
          entity: 'affiliate'
        })}</label>
            <span>
              {'- '}
              {formatCurrency(price * (affiliate?.discountPercentage / 100), locale)}
            </span>
          </li>}

      {canUseDiscount && discount?.amount > 0 && <li>
          <label className="m-0">{t('payment.common.discount', {
          entity: 'coupon'
        })}</label>
          <span>
            {'- '}
            {formatCurrency(discountAmount, locale)}
          </span>
        </li>}

      {children}
    </ul>;
}