'use client';

import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { matchfyApi } from '@/api';
import Alert from '@/components/ui/Alert';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { RangeSlider } from '@/components/ui/Field';
import PaymentGateway from '@/components/ui/Payment/PaymentGateway';
import Spinner from '@/components/ui/Spinner';
import { CURATOR_PROMOTION_MAX_WEEK, CURATOR_PROMOTION_MIN_WEEK, STRIPE_PRODUCTS } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { cn } from '@/utils';
const isCuratorFeatured = user => {
  if (!user?.featured) return false;
  const endDate = new Date(user.featured.endsAt);
  return endDate >= Date.now();
};
const CuratorPromote = ({
  className = '',
  small = false,
  triggerVariant = 'primary',
  triggerClasses
}) => {
  const [open, setOpen] = useState(false);
  const [profileFeaturedPrice, setProfileFeaturedPrice] = useState(0);
  const [timeToEnd, setTimeToEnd] = useState(0);
  const [timeToEndUnit, setTimeToEndUnit] = useState('days');
  const [duration, setDuration] = useState(CURATOR_PROMOTION_MAX_WEEK / 2);
  const t = useTranslations();
  const {
    data: session
  } = useSession();
  const user = session?.user;
  const featured = isCuratorFeatured(user);
  const marks = {};
  for (let i = CURATOR_PROMOTION_MIN_WEEK; i <= CURATOR_PROMOTION_MAX_WEEK; i++) {
    marks[i] = i;
  }
  const {
    data,
    isLoading,
    isSuccess
  } = useQuery({
    queryKey: ['profileFeaturedPrice'],
    queryFn: () => matchfyApi.getProfileFeaturedPrice()
  });
  useEffect(() => {
    if (isSuccess) {
      setProfileFeaturedPrice(data.profileFeaturedPrice);
    }
  }, [isSuccess, data]);
  useEffect(() => {
    if (user?.featured) {
      let timeLeft = dayjs(user.featured.endsAt).diff(dayjs(), 'days');
      if (timeLeft === 0) {
        timeLeft = dayjs(user.featured.endsAt).diff(dayjs(), 'hours');
        setTimeToEndUnit('hours');
      }
      setTimeToEnd(timeLeft);
    }
  }, [user, featured]);
  const classes = cn({
    'payment-modal': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="CuratorPromote" data-sentry-source-file="CuratorPromote.jsx">
      <Button small={small} className={triggerClasses ?? null} icon="Star" variant={triggerVariant} disabled={false} label={t('curator.promote.cta')} onClick={() => setOpen(true)} data-sentry-element="Button" data-sentry-source-file="CuratorPromote.jsx" />

      <Dialog open={open} onOpenChange={setOpen} data-sentry-element="Dialog" data-sentry-source-file="CuratorPromote.jsx">
        <DialogContent data-sentry-element="DialogContent" data-sentry-source-file="CuratorPromote.jsx">
          <DialogHeader data-sentry-element="DialogHeader" data-sentry-source-file="CuratorPromote.jsx">
            <DialogTitle data-sentry-element="DialogTitle" data-sentry-source-file="CuratorPromote.jsx">{t('curator.promote.title')}</DialogTitle>
          </DialogHeader>

          <p>{t('curator.promote.description', {
            shouldParse: true
          })}</p>

          <ul className="mb-5 list">
            <li>{t('curator.promote.features.0')}</li>
            <li>{t('curator.promote.features.1')}</li>
          </ul>

          {isLoading && <Spinner />}

          {!featured && !isLoading && profileFeaturedPrice > 0 && <div>
              <RangeSlider className="mb-5" label={t('curator.promote.duration')} min={CURATOR_PROMOTION_MIN_WEEK} max={CURATOR_PROMOTION_MAX_WEEK} value={duration} marks={marks} onChange={setDuration} step={1} />

              <PaymentGateway btnLabel={t('curator.promote.cta')} plan={STRIPE_PRODUCTS.PROFILE_FEATURED} planName={'profile_featured'} price={duration * profileFeaturedPrice} payload={{
            duration: duration
          }} mode="payment" creditsDiscount={0.9} onClose={() => setOpen(false)} />
            </div>}

          {featured && timeToEnd > 0 && <Alert variant="success" className="text-center">
              <p className="md:text-xl">
                {t('curator.featured.timer.text')}
                {timeToEndUnit === 'days' ? t('curator.featured.timer.unit.days', {
              timeLeft: timeToEnd
            }) : t('curator.featured.timer.unit.hours', {
              timeLeft: timeToEnd
            })}
              </p>
            </Alert>}
        </DialogContent>
      </Dialog>
    </div>;
};
export default CuratorPromote;