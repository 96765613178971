'use client'

import { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

export function Portal({ id, children }) {
  const [mounted, setMounted] = useState(false)

  const element = document.querySelector(`#${id}`)

  useEffect(() => {
    setMounted(true)

    return () => setMounted(false)
  }, [])

  return mounted && element ? createPortal(children, element) : null
}

export default Portal
