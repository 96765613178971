'use client';

import { useEffect, useState } from 'react';
import DiscountForm from './DiscountForm';
import Badge from '@/components/ui/Badge';
import { CURRENCY_SYMBOL } from '@/data';
import { useSession, useTranslations } from '@/hooks';
import { useAffiliateStore, useCreditsStore, useDiscountStore } from '@/store';
import { cn } from '@/utils';
export default function PaymentCalculator({
  autoApply = false,
  planName,
  billing = 'one-time',
  className,
  creditsDiscount,
  disabled = false,
  disableAffiliate,
  disableCredits,
  disableCoupon = false,
  maxCredits,
  onChange,
  price
}) {
  const [creditsScoreUsed, setCreditsScoreUsed] = useState(0);
  const {
    affiliate
  } = useAffiliateStore();
  const {
    credits
  } = useCreditsStore();
  const {
    applyDiscountCode,
    calculateDiscountAmount,
    canUseDiscount,
    discount
  } = useDiscountStore();
  const {
    data: session
  } = useSession();
  const {
    user
  } = session || {};
  const canUseReferralScore = credits > 0 && creditsScoreUsed === 0;
  const [paymentPrice, setPaymentPrice] = useState(parseFloat(price));
  const [affiliateDiscount, setAffiliateDiscount] = useState(0);
  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [useCredits, setUseCredits] = useState(autoApply && canUseReferralScore);
  const t = useTranslations();
  const calculateAffiliatePrice = price => {
    if (affiliate?.code && affiliate?.discountPercentage && !disableAffiliate) {
      setAffiliateDiscount(affiliate?.discountPercentage);
      return price * (1 - affiliate?.discountPercentage / 100);
    }
    return price;
  };
  const areCreditsDisabled = () => {
    return !canUseReferralScore && !useCredits || disabled || maxCredits === 0;
  };
  const handleUseCredits = async () => {
    if (disableCredits) {
      return;
    }
    const basePrice = discount && canUseDiscount ? price - calculateDiscountAmount(price) : calculateAffiliatePrice(price);
    const discountAmount = discount && canUseDiscount ? calculateDiscountAmount(price) : 0;
    const couponDiscountPercentage = discount.isPercent ? discount.amount : Math.floor(discountAmount / price * 100);
    let creditsToUse = 0;
    if (useCredits) {
      const usableDiscountPercentage = Math.round((creditsDiscount - (!discount ? affiliateDiscount / 100 : 0) - couponDiscountPercentage / 100) * 100) / 100;
      creditsToUse = Math.floor(Math.min(credits, price * usableDiscountPercentage));
      if (maxCredits && creditsToUse > maxCredits) {
        creditsToUse = maxCredits;
      }
    }
    const updatedPrice = basePrice - creditsToUse;
    setPaymentPrice(updatedPrice);
    setDiscountPercentage((1 - updatedPrice / price) * 100);
    setCreditsScoreUsed(creditsToUse);
    if (onChange) {
      onChange(updatedPrice, creditsToUse, affiliateDiscount);
    }
  };
  useEffect(() => {
    handleUseCredits();
  }, [price, useCredits, disableCredits, canUseDiscount, affiliate, affiliateDiscount, discount]);
  useEffect(() => {
    applyDiscountCode(planName, price);
    handleUseCredits();
  }, []);
  const classes = cn({
    'payment-calculator': true,
    [className]: className
  });
  const PaymentDiscount = () => {
    if (discountPercentage > 0) {
      return <Badge variant="green-light" className="payment-discount">
          {discountPercentage.toFixed(0)}%
        </Badge>;
    }
  };
  const PaymentOneTime = () => {
    if (!affiliate || !useCredits) {
      return <div className="payment-card">
          <label>{t('payment.popup.amount')}</label>
          <div>
            <h4 className="text-green">
              {CURRENCY_SYMBOL}
              {paymentPrice.toFixed(2)}
            </h4>
            <span className="tracking-wide uppercase text-small opacity-70">
              {t('payment.common.billing.one_time')}
            </span>
          </div>
          <PaymentDiscount />
        </div>;
    } else if (affiliate || useCredits) {
      return <div className="payment-card payment-one-time sale">
          <label>{t('payment.popup.amount')}</label>
          <div>
            {price > paymentPrice && <h4 className="mr-2 font-medium line-through opacity-50">
                {CURRENCY_SYMBOL}
                {price.toFixed(2)}
              </h4>}
            <h4 className="font-bold text-green">
              {CURRENCY_SYMBOL}
              {paymentPrice.toFixed(2)}
            </h4>
            <span className="tracking-wide uppercase text-small opacity-70">
              {t('payment.common.billing.one_time')}
            </span>
          </div>
          <PaymentDiscount />
        </div>;
    }
  };
  const PaymentMonthly = () => {
    if (!affiliate && !useCredits) {
      return <div className="payment-card payment-monthly">
          <label>{t('payment.popup.amount')}</label>

          <div>
            <h4 className="text-green">
              {CURRENCY_SYMBOL}
              {(paymentPrice / 12).toFixed(2)}
              <span className="ml-1">
                {'/'}
                {t('common.time.periods.monthly')}
              </span>
            </h4>
            <span className="tracking-wide uppercase text-small opacity-70">
              {creditsScoreUsed > 0 ? t('payment.common.billing.first_year') : t('payment.common.billing.yearly')}
            </span>
          </div>
          <PaymentDiscount />
        </div>;
    } else if (affiliate || useCredits) {
      return <div className="payment-card payment-monthly sale">
          <label>{t('payment.popup.amount')}</label>
          <div>
            <h4 className="mr-2 font-medium line-through opacity-50">
              {CURRENCY_SYMBOL}
              {(price / 12).toFixed(2)}
              <span className="ml-1">
                {'/'}
                {t('common.time.periods.monthly')}
              </span>
            </h4>
            <h4 className="font-bold text-green">
              {CURRENCY_SYMBOL}
              {(paymentPrice / 12).toFixed(2)}
              <span className="ml-1">
                {'/'}
                {t('common.time.periods.monthly')}
              </span>
            </h4>
            <span className="tracking-wide uppercase text-small opacity-70">
              {t('payment.common.billing.yearly')}
            </span>
          </div>
          <PaymentDiscount />
        </div>;
    }
  };
  return <div className={classes} data-sentry-component="PaymentCalculator" data-sentry-source-file="PaymentCalculator.jsx">
      <div className={cn({
      'grid gap-4 mb-4': true,
      'grid-cols-2': !areCreditsDisabled()
    })}>
        {billing === 'one-time' && <PaymentOneTime />}
        {billing === 'monthly' && <PaymentMonthly />}

        {!areCreditsDisabled() && <div className="payment-card">
            <p className="text-label">
              {t('payment.popup.you_have_score', {
            bonusScore: credits - creditsScoreUsed
          })}
            </p>

            <label className={cn({
          'cursor-not-allowed opacity-50': areCreditsDisabled()
        })}>
              <input type="checkbox" checked={useCredits} onChange={e => setUseCredits(e.target.checked)} disabled={areCreditsDisabled()} />
              {canUseReferralScore ? t('payment.popup.use_my_credits') : t('payment.popup.credit_used')}
            </label>
          </div>}

        {!discount && !disableAffiliate && affiliate && affiliate.discountPercentage && <div className="col-span-2">
            <Badge variant="green-light" label={t('affiliate.applied')} value={`${affiliate?.code} ${affiliate.discountPercentage}%`} />
          </div>}

        {!disableCoupon && <DiscountForm className="col-span-2" disableAffiliate={disableAffiliate} plan={planName} price={price} />}
      </div>
    </div>;
}