import * as ProgressPrimitive from '@radix-ui/react-progress';
import { motion } from 'framer-motion';
import * as React from 'react';
import Icon from '@/components/ui/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { cn } from '@/utils';
export const ProgressBar = React.forwardRef(({
  className,
  icon,
  compact,
  value,
  label,
  labelClasses,
  valueLabel,
  max = 100,
  variant = 'primary',
  tooltipText,
  tooltipProps,
  ...props
}, ref) => {
  const mainVariants = ['primary', 'secondary'];
  const progressWrapperCn = cn({
    'relative h-7 w-full overflow-hidden rounded-md': true,
    [`bg-${variant}-500/10 border border-${variant}-500/20`]: !mainVariants.includes(variant),
    [`bg-${variant}-brand/10 border border-${variant}-brand/20`]: mainVariants.includes(variant),
    [className]: className
  });
  const progressBarCn = cn({
    'h-full w-full flex-1 rounded-l-md overflow-hidden': true,
    'opacity-90': true,
    [`bg-${variant}-500`]: !mainVariants.includes(variant),
    [`bg-${variant}-brand`]: mainVariants.includes(variant)
  });
  const labelWrapperCn = cn({
    'flex items-center justify-between gap-2': true,
    'absolute top-[50%] -translate-y-1/2 left-0 w-full px-2 z-10': compact
  });
  const labelCn = cn({
    'text-sm': true,
    [labelClasses]: labelClasses
  });
  const valueCn = cn({
    'text-sm font-medium opacity-90': true,
    'w-full text-right': !label
  });
  const progressContent = <div className="relative flex flex-col gap-2">
        {(label || valueLabel) && <div className={labelWrapperCn}>
            {label && <div className="inline-flex items-center gap-1">
                {icon && <Icon name={icon} />}

                <span className={labelCn}>{label}</span>
              </div>}

            {valueLabel && <div className={valueCn}>{valueLabel}</div>}
          </div>}

        <ProgressPrimitive.Root ref={ref} className={progressWrapperCn} {...props}>
          <motion.div className={cn(progressBarCn)} initial={{
        x: '-100%'
      }} animate={{
        x: `${-100 + (value || 0) / max * 100}%`
      }} transition={{
        duration: 0.5,
        ease: 'easeInOut'
      }} />
        </ProgressPrimitive.Root>
      </div>;
  return tooltipText ? <Tooltip>
        <TooltipTrigger asChild>
          {progressContent}
        </TooltipTrigger>
        <TooltipContent {...tooltipProps}>
          <span>{tooltipText}</span>
        </TooltipContent>
      </Tooltip> : progressContent;
});
ProgressBar.displayName = ProgressPrimitive.Root.displayName;
export default React.memo(ProgressBar);