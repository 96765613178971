import PaymentDetails from './PaymentDetails';
import PaymentTotal from './PaymentTotal';
import { cn } from '@/utils';
export default function PaymentSummary({
  billing = 'one-time',
  creditsScoreUsed,
  disableAffiliate,
  disableCoupon,
  paymentPrice,
  price,
  className
}) {
  const classes = cn({
    'payment-summary': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="PaymentSummary" data-sentry-source-file="PaymentSummary.jsx">
      <div className="grid grid-cols-2 gap-2 mb-6">
        <div className="col-span-2">
          <PaymentDetails billing={billing} creditsScoreUsed={creditsScoreUsed} disableAffiliate={disableAffiliate} disableCoupon={disableCoupon} price={price} data-sentry-element="PaymentDetails" data-sentry-source-file="PaymentSummary.jsx" />
        </div>

        <div className="col-span-2">
          <PaymentTotal paymentPrice={paymentPrice} price={price} data-sentry-element="PaymentTotal" data-sentry-source-file="PaymentSummary.jsx" />
        </div>
      </div>
    </div>;
}