import Button from '@/components/ui/Button';
import { useTranslations } from '@/hooks';
import { toast } from '@/lib';
export default function CopyMessage({
  text,
  label,
  className
}) {
  const t = useTranslations();
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      toast('success', t('feedback.success.copied'));
    } catch (err) {
      toast('error', t('messages.copyFailed'));
    }
  };
  return <Button small onClick={handleCopy} label={label ?? ''} variant="primary-light" className={`button-copy ${className ?? ''}`} icon="Copy" data-sentry-element="Button" data-sentry-component="CopyMessage" data-sentry-source-file="CopyMessage.jsx" />;
}