import Button from '@/components/ui/Button';
import { useTranslations } from '@/hooks';
import { toast } from '@/lib';
export default function CopyToClipboard({
  text,
  label,
  variant = 'primary-light',
  className
}) {
  const t = useTranslations();
  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text);
      toast('success', t('feedback.success.copied'));
    } catch (err) {
      toast('error', t('messages.copyFailed'));
    }
  };
  return <Button small onClick={handleCopy} label={label ?? ''} variant={variant} className={`button-copy ${className ?? ''}`} icon="Copy" data-sentry-element="Button" data-sentry-component="CopyToClipboard" data-sentry-source-file="CopyToClipboard.jsx" />;
}