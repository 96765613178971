import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useContext } from 'react';
import StripeForm from './StripeForm';
import { ThemeContext } from '@/context/ThemeProvider';
import { STRIPE_CONFIG } from '@/data';
const stripePromise = loadStripe(STRIPE_CONFIG.PUBLIC_KEY);
export default function StripeGateway({
  amount,
  disabled,
  mode = 'payment',
  plan,
  profile,
  extra,
  ...props
}) {
  const {
    currentTheme
  } = useContext(ThemeContext);
  const stripeTheme = currentTheme === 'dark' ? 'night' : 'stripe';
  const price = Math.round(amount * 100);
  const stripeOptions = {
    amount: price,
    currency: STRIPE_CONFIG.CURRENCY,
    mode,
    automatic_payment_methods: {
      enabled: true
    },
    appearance: {
      theme: stripeTheme
    }
  };
  return <div className="payment-gateway" data-sentry-component="StripeGateway" data-sentry-source-file="StripeGateway.jsx">
      <Elements stripe={stripePromise} options={stripeOptions} data-sentry-element="Elements" data-sentry-source-file="StripeGateway.jsx">
        <StripeForm amount={price} mode={mode} disabled={disabled} plan={plan} profile={profile} extra={extra} {...props} data-sentry-element="StripeForm" data-sentry-source-file="StripeGateway.jsx" />
      </Elements>
    </div>;
}