'use client';

import CodiceFiscale from 'codice-fiscale-js';
import { checkVAT, countries as vatCountries } from 'jsvat';
import { useEffect, useRef, useState, useMemo, useCallback } from 'react';
import Autocomplete from 'react-google-autocomplete';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import { matchfyApi } from '@/api';
import Button from '@/components/ui/Button';
import Field from '@/components/ui/Field';
import { SectionHeader } from '@/components/ui/Section';
import { GOOGLE_MAPS_API_KEY } from '@/data';
import countries from '@/data/countries.json';
import { useSession, useTranslations } from '@/hooks';
import { useRouter } from '@/i18n/routing';
import { toast } from '@/lib';
import { isValidEmail, getBillingInfo, cn, planRedirect } from '@/utils';
const validation = {
  firstName: value => value.length,
  lastName: value => value.length,
  country: value => value.length,
  city: value => value.length,
  address: value => value.length,
  zipCode: value => value.length,
  provinceState: value => value.length,
  email: value => value.length && isValidEmail(value),
  phone: value => value && isValidPhoneNumber(value),
  companyName: value => value.length,
  vatNumber: value => value.length && checkVAT(value, vatCountries).isValid,
  pec: value => value.length,
  codiceFiscale: value => {
    try {
      const cf = new CodiceFiscale(value);
      cf.toJSON();
      return true;
    } catch (err) {
      return false;
    }
  },
  codiceUnivoco: value => value.length
};
const validate = (value, type) => {
  if (typeof value !== 'string' || !validation[type]) {
    return true;
  }
  return Boolean(validation[type](value.trim()));
};
const italianFields = ['codiceFiscale', 'codiceUnivoco', 'pec'];
const companyFields = ['companyName', 'vatNumber', 'codiceUnivoco', 'pec'];
export default function BillingEdit({
  onSuccess,
  onCancel,
  plan,
  footer = true,
  className
}) {
  const {
    data: session,
    update
  } = useSession();
  const {
    user
  } = session;
  const router = useRouter();
  const billingInfo = useMemo(() => getBillingInfo(user?.billingInfo), [user?.billingInfo]);
  const [stateBillingInfo, updateStateBillingInfo] = useState(billingInfo);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [isFormValid, setFormValid] = useState(true);
  const [isPhoneValid, setPhoneValid] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [defaultCountryValue, setDefaultCountryValue] = useState(countries.find(country => country.value === billingInfo.country));
  const isItaly = stateBillingInfo.country === 'IT';
  const t = useTranslations();
  const refs = {
    firstName: useRef(null),
    lastName: useRef(null),
    country: useRef(null),
    city: useRef(null),
    address: useRef(null),
    zipCode: useRef(null),
    provinceState: useRef(null),
    email: useRef(null),
    phone: useRef(null),
    companyName: useRef(null),
    vatNumber: useRef(null),
    pec: useRef(null),
    codiceFiscale: useRef(null),
    codiceUnivoco: useRef(null)
  };
  useEffect(() => {
    if (billingInfo.phone && billingInfo.phone.charAt(0) === '+') {
      updateStateBillingInfo(currentState => ({
        ...currentState,
        phone: billingInfo.phone
      }));
    }
  }, [billingInfo]);
  useEffect(() => {
    setDefaultCountryValue(countries.find(country => country.value === stateBillingInfo.country));
    if (isItaly && stateBillingInfo?.provinceState.length > 2) {
      updateStateBillingInfo(currentState => ({
        ...currentState,
        provinceState: stateBillingInfo.provinceState.substring(0, 2).toUpperCase()
      }));
    }
  }, [stateBillingInfo.country]);
  const countryChangeHandler = option => {
    const {
      value
    } = option ? option : '';
    updateStateBillingInfo(prevState => ({
      ...prevState,
      country: value
    }));
  };
  const phoneChangeHandler = value => {
    updateStateBillingInfo(prevState => ({
      ...prevState,
      phone: value
    }));
  };
  const validateElement = (key, isFromBlurEvent) => {
    if (!stateBillingInfo.isVatCompany && companyFields.includes(key)) {
      return true;
    }
    if (!isItaly && italianFields.includes(key)) {
      return true;
    }
    if (key === 'phone') {
      if (isFromBlurEvent) {
        if (!stateBillingInfo.phone || stateBillingInfo.phone.length === 0) {
          setFormErrors(prevState => ({
            ...prevState,
            phone: [t(`form.error.required`)]
          }));
          return false;
        }
      }
      const phoneValid = validate(stateBillingInfo[key], key);
      setPhoneValid(phoneValid);
      return phoneValid;
    }
    return validate(stateBillingInfo[key], key);
  };
  const validateForm = (elementToMark, isFromBlurEvent = false) => {
    const inputs = Object.entries(refs);
    let formValid = true;
    inputs.forEach(([key, ref]) => {
      const validationResult = validateElement(key, isFromBlurEvent);
      if (!validationResult && ref.current) {
        formValid = validationResult;
        const htmlElement = key === 'country' ? ref.current.inputRef : ref.current;
        if (htmlElement === elementToMark) {
          setFormErrors(prevState => ({
            ...prevState,
            [key]: [t('form.error.required')]
          }));
        }
      } else {
        setFormErrors(prevState => ({
          ...prevState,
          [key]: null
        }));
      }
    });
    setFormValid(formValid);
    return formValid;
  };
  const inputChangeHandler = ({
    target
  }) => {
    let value = target.value;
    if (target.type === 'checkbox') {
      value = target.checked;
    } else if (target.name === 'codiceFiscale') {
      value = value.toUpperCase();
    }
    updateStateBillingInfo(prevState => ({
      ...prevState,
      [target.name]: value
    }));
  };
  const blurHandler = ({
    target
  }, isPhoneField = false) => validateForm(target, isPhoneField);
  const submitHandler = async evt => {
    evt.preventDefault();
    if (isSubmitLoading || !isFormValid) {
      return;
    }
    setSubmitLoading(true);
    try {
      await matchfyApi.saveBillingInfo(stateBillingInfo);
      update();
      setSubmitLoading(false);
      toast('success', t('feedback.success.general.info_saved'));
      if (onSuccess) {
        onSuccess();
      }
      if (plan) {
        router.push(planRedirect(plan));
      }
    } catch (error) {
      console.log(error);
      setSubmitLoading(false);
      toast('error', t('feedback.error.default'));
    }
  };
  useEffect(() => {
    validateForm();
    if (!isItaly) {
      updateStateBillingInfo(currentState => ({
        ...currentState,
        ...italianFields.reduce((acc, italianField) => ({
          ...acc,
          [italianField]: ''
        }), {})
      }));
    }
    if (!stateBillingInfo.isVatCompany) {
      updateStateBillingInfo(currentState => ({
        ...currentState,
        ...companyFields.reduce((acc, companyField) => ({
          ...acc,
          [companyField]: ''
        }), {})
      }));
    }
  }, [stateBillingInfo.isVatCompany, stateBillingInfo.country]);
  const buttonDisabled = isSubmitLoading || !isFormValid;
  const handlePlaceSelected = useCallback(place => {
    if (!place.address_components) return;
    const getAddressComponent = type => {
      const component = place.address_components.find(comp => comp.types.includes(type));
      return component ? component.long_name : '';
    };
    const getCountryCode = () => {
      const country = place.address_components.find(comp => comp.types.includes('country'));
      return country ? country.short_name : '';
    };
    const streetNumber = getAddressComponent('street_number');
    const street = getAddressComponent('route');
    const address = streetNumber ? `${street} ${streetNumber}` : street;
    const billingAddress = {
      address,
      city: getAddressComponent('locality'),
      provinceState: getAddressComponent('administrative_area_level_2') || getAddressComponent('administrative_area_level_1'),
      country: getCountryCode(),
      zipCode: getAddressComponent('postal_code')
    };
    updateStateBillingInfo(currentState => ({
      ...currentState,
      ...billingAddress
    }));
  }, []);
  const classes = cn({
    [className]: className
  });
  return <div className={classes} data-sentry-component="BillingEdit" data-sentry-source-file="BillingEdit.jsx">
      <SectionHeader sticky title={t('user.actions.edit_billing')} className="mb-4" icon="ReceiptText" actions={<>
            {onCancel && <Button variant="inverse" icon="Undo" onClick={onCancel}>
              {t('common.actions.cancel')}
            </Button>}

            <Button loading={isSubmitLoading} onClick={submitHandler} disabled={buttonDisabled} icon="Check" type="submit" label={t('common.actions.save')} />
          </>} data-sentry-element="SectionHeader" data-sentry-source-file="BillingEdit.jsx" />

      <form className="form">
        <Field label={t('field.firstName.label')} ref={refs.firstName} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.firstName} placeholder={t('field.firstName.placeholder')} type="text" name="firstName" id="billing-first-name" required errors={formErrors.firstName} data-sentry-element="Field" data-sentry-source-file="BillingEdit.jsx" />

        <Field label={t('field.lastName.label')} ref={refs.lastName} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.lastName} placeholder={t('field.lastName.placeholder')} type="text" name="lastName" id="billing-last-name" required errors={formErrors.lastName} data-sentry-element="Field" data-sentry-source-file="BillingEdit.jsx" />

        <Field label={t('field.country.label', {
        count: 1
      })} type="select" id="billing-country" name="country" onChange={countryChangeHandler} onBlur={blurHandler} isClearable options={countries} defaultValue={defaultCountryValue} placeholder={t('field.country.placeholder')} required className="select" classNamePrefix="select" ref={refs.country} errors={formErrors.country} data-sentry-element="Field" data-sentry-source-file="BillingEdit.jsx" />

        <div className="form-group">
          <label htmlFor="billing-address">
            {t('field.address.label')}
          </label>

          <Autocomplete apiKey={GOOGLE_MAPS_API_KEY} type="text" onPlaceSelected={handlePlaceSelected} options={{
          types: ['address'],
          fields: ['address_components', 'formatted_address']
        }} defaultValue={stateBillingInfo.address} className="form-control" debounce={300} placeholder={t('field.address.placeholder')} id="billing-address" name="address" required data-sentry-element="Autocomplete" data-sentry-source-file="BillingEdit.jsx" />

          {formErrors.address && <span className="mt-1 error-message">
              {formErrors.address}
            </span>}
        </div>

        <Field ref={refs.city} label={t('field.city.label')} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.city} type="text" name="city" id="billing-city" required placeholder={t('field.city.placeholder')} errors={formErrors.city} data-sentry-element="Field" data-sentry-source-file="BillingEdit.jsx" />

        <Field ref={refs.zipCode} label={t('field.zipCode.label')} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.zipCode} type="text" name="zipCode" id="billing-zip-code" required placeholder={t('field.zipCode.placeholder')} errors={formErrors.zipCode} data-sentry-element="Field" data-sentry-source-file="BillingEdit.jsx" />

        <Field ref={refs.provinceState} label={t('field.provinceState.label')} onBlur={blurHandler} onChange={inputChangeHandler} maxLength={isItaly ? 2 : undefined} value={stateBillingInfo.provinceState} type="text" name="provinceState" id="billing-province-state" required placeholder={t('field.provinceState.placeholder')} errors={formErrors.provinceState} data-sentry-element="Field" data-sentry-source-file="BillingEdit.jsx" />

        {isItaly && <Field ref={refs.codiceFiscale} label={t('field.taxCode.label')} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.codiceFiscale} type="text" name="codiceFiscale" id="billing-codiceFiscale" placeholder={t('field.taxCode.placeholder')} errors={formErrors.codiceFiscale} />}

        <Field label={t('field.email.label')} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.email} ref={refs.email} type="text" name="email" id="billing-email" required placeholder={t('field.email.placeholder')} errors={formErrors.email} data-sentry-element="Field" data-sentry-source-file="BillingEdit.jsx" />

        <div className="form-group">
          <label htmlFor="billing-phone">
            {t('field.phone.label')}
          </label>

          <PhoneInput placeholder={t('field.phone.label')} defaultCountry={!stateBillingInfo.phone ? stateBillingInfo.country : ''} value={stateBillingInfo.phone} onChange={phoneChangeHandler} onBlur={e => blurHandler(e, true)} data-sentry-element="PhoneInput" data-sentry-source-file="BillingEdit.jsx" />

          {!isPhoneValid && <span className="mt-1 error-message">
              {t('field.phone.error')}
            </span>}

          {formErrors.phone && isPhoneValid && <span className="mt-1 error-message">
              {t('form.error.required')}
            </span>}
        </div>

        <div className="payment-popup-text">
          <label className="is-company-label">
            <input type="checkbox" className="custom-control-input" checked={stateBillingInfo.isVatCompany} onChange={inputChangeHandler} name="isVatCompany" />
            {t('field.vatNumber.checkbox_label')}
          </label>
        </div>

        {stateBillingInfo.isVatCompany && <>
            <Field label={t('field.companyName.label')} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.companyName} ref={refs.companyName} type="text" name="companyName" id="billing-company-name" required placeholder={t('field.companyName.placeholder')} errors={formErrors.companyName} />

            <Field label={t('field.vatNumber.label')} ref={refs.vatNumber} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.vatNumber} type="text" name="vatNumber" id="billing-vat-number" required placeholder={t('field.vatNumber.placeholder')} errors={formErrors.vatNumber} />

            {isItaly && <>
                <Field ref={refs.codiceUnivoco} label={t('field.codiceUnivoco.label')} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.codiceUnivoco} type="text" name="codiceUnivoco" id="billing-codice-univoco" placeholder={t('field.codiceUnivoco.placeholder')} errors={formErrors.codiceUnivoco} />

                <Field ref={refs.pec} label={t('field.pec.label')} onBlur={blurHandler} onChange={inputChangeHandler} value={stateBillingInfo.pec} type="text" name="pec" id="billing-pec" placeholder={t('field.pec.placeholder')} errors={formErrors.pec} />
              </>}
          </>}

        {footer && <div className="flex justify-end gap-2">
            {onCancel && <Button variant="inverse" icon="Undo" onClick={onCancel}>
              {t('common.actions.cancel')}
            </Button>}

            <Button loading={isSubmitLoading} onClick={submitHandler} disabled={buttonDisabled} icon="Check" type="submit" label={t('common.actions.save')} />
          </div>}
      </form>
    </div>;
}