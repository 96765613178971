import { useLocale, useTranslations } from '@/hooks';
import { cn, formatCurrency } from '@/utils';
export default function PaymentTotal({
  paymentPrice,
  price,
  className
}) {
  const t = useTranslations();
  const {
    locale
  } = useLocale();
  const classes = cn({
    'payment-total': true,
    [className]: className
  });
  return <div className={classes} data-sentry-component="PaymentTotal" data-sentry-source-file="PaymentTotal.jsx">
      <label className="m-0">
        {t('payment.common.payment_total')}
      </label>
      <h4>
        {price > paymentPrice && <span className="mr-2 font-medium line-through opacity-50">
            {formatCurrency(price, locale)}
          </span>}

        {paymentPrice && <span className="text-green">
            {formatCurrency(paymentPrice, locale)}
          </span>}
      </h4>
    </div>;
}