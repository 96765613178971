import { ApplePay, GPayLogo, KlarnaLogo, MastercardLogo, PayPalLogo, StripeLogo, VisaLogo } from './PaymentLogos';
import Icon from '@/components/ui/Icon';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export default function PaymentSecure({
  className
}) {
  const t = useTranslations();
  const classes = cn({
    'payment-secure': true,
    [className]: className
  });
  const PaymentLogo = ({
    logo,
    className
  }) => {
    const logoClasses = cn({
      'payment-logo': true,
      [className]: className
    });
    return <div className={logoClasses} data-sentry-component="PaymentLogo" data-sentry-source-file="PaymentSecure.jsx">{logo}</div>;
  };
  return <div className={classes} data-sentry-component="PaymentSecure" data-sentry-source-file="PaymentSecure.jsx">
      <div className="grid grid-cols-2 gap-4 mb-4">
        <div className="payment-secure-details text-green">
          <Icon name="Lock" className="text-green" data-sentry-element="Icon" data-sentry-source-file="PaymentSecure.jsx" />
          <span>{t('payment.secure.lock')}</span>
        </div>
        <div className="payment-secure-details text-blue">
          <Icon name="ShieldCheck" className="" data-sentry-element="Icon" data-sentry-source-file="PaymentSecure.jsx" />
          <span>{t('payment.secure.shield')}</span>
        </div>
      </div>

      <div className="payment-secure-logos">
        <PaymentLogo logo={<ApplePay />} data-sentry-element="PaymentLogo" data-sentry-source-file="PaymentSecure.jsx" />
        <PaymentLogo logo={<GPayLogo />} data-sentry-element="PaymentLogo" data-sentry-source-file="PaymentSecure.jsx" />
        <PaymentLogo logo={<KlarnaLogo />} data-sentry-element="PaymentLogo" data-sentry-source-file="PaymentSecure.jsx" />
        <PaymentLogo logo={<PayPalLogo />} data-sentry-element="PaymentLogo" data-sentry-source-file="PaymentSecure.jsx" />
        <PaymentLogo logo={<MastercardLogo />} data-sentry-element="PaymentLogo" data-sentry-source-file="PaymentSecure.jsx" />
        <PaymentLogo logo={<VisaLogo />} data-sentry-element="PaymentLogo" data-sentry-source-file="PaymentSecure.jsx" />
        <PaymentLogo logo={<StripeLogo />} data-sentry-element="PaymentLogo" data-sentry-source-file="PaymentSecure.jsx" />
      </div>
    </div>;
}