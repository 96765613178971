'use client';

import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { useSticky } from '@/hooks';
import { useRouter } from '@/i18n/routing';
import { cn } from '@/utils';
const SectionHeader = ({
  breadcrumbs,
  title,
  image,
  back = false,
  badge,
  subtitle,
  icon,
  actions,
  sticky,
  stickyConfig,
  className
}) => {
  const router = useRouter();
  const {
    elementRef,
    style: stickyStyle,
    className: stickyClassName
  } = useSticky(stickyConfig);
  const classes = cn({
    'section-header': true,
    'with-actions': actions,
    [className]: className,
    [stickyClassName]: sticky && stickyClassName
  });
  const handleBackClick = () => {
    if (typeof back === 'string') {
      router.push(back);
    } else {
      router.back();
    }
  };
  return <div ref={sticky ? elementRef : undefined} className={classes} style={sticky ? stickyStyle : undefined} data-sentry-component="SectionHeader" data-sentry-source-file="SectionHeader.jsx">
      {breadcrumbs && breadcrumbs}
      <div className="section-header-content">
        <div className="flex items-center gap-2">
          {back && <Button variant="ghost" className="mr-2" icon="ArrowLeft" onClick={handleBackClick} />}

          <h3 className="inline-flex items-center gap-2">
            {icon && !back && <Icon className="mr-2" name={icon} />}

            {image && <div className="relative w-10 h-10 mr-2 overflow-hidden rounded">
                <img src={image} alt={title} className="absolute object-cover w-full h-full" />
              </div>}

            {title}
          </h3>

          {subtitle && <h4>{subtitle}</h4>}

          {badge && badge}
        </div>

        {actions && <div className="section-header-actions">{actions}</div>}
      </div>
    </div>;
};
export default SectionHeader;