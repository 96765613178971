'use client';

import debounce from 'lodash/debounce';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { matchfyApi } from '@/api';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
const loadOptions = async (query, callback) => {
  try {
    const results = await matchfyApi.listGenres(query);
    callback(results.map(genre => ({
      label: genre.query,
      value: genre.query
    })));
  } catch (error) {
    console.error(error);
  }
};
const debouncedLoadOptions = debounce(loadOptions, 500);
const Genres = React.forwardRef(({
  id,
  defaultValue,
  genres,
  onGenreSelect,
  hideTitle = false,
  title,
  isDisabled,
  label,
  errors,
  hideLabel = false,
  maxLimit,
  className
}, ref) => {
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const t = useTranslations();
  const handleGenreChange = (options, {
    action
  }) => {
    const newOptions = options || [];
    setValue(newOptions);
    const actionsList = ['select-option', 'remove-value', 'clear'];
    if (actionsList.includes(action)) {
      onGenreSelect(newOptions.map(option => option.value));
    }
  };
  const checkIfOptionDisabled = option => {
    const isMaxLimitReached = genres && genres.length >= maxLimit;
    const isOptionAlreadySelected = genres && genres.includes(option.value);
    return isMaxLimitReached && !isOptionAlreadySelected;
  };
  useEffect(() => {
    if (defaultValue) {
      setValue(defaultValue.map(genre => ({
        value: genre,
        label: genre
      })));
      if (onGenreSelect) {
        onGenreSelect(defaultValue);
      }
    }
  }, [defaultValue]);
  useEffect(() => {
    if (genres) {
      setValue(genres.map(genre => ({
        value: genre,
        label: genre
      })));
    }
  }, [genres]);
  useEffect(() => {
    setIsLoading(true);

    // Load initial options with empty query
    loadOptions('', results => {
      setOptions(results);
      setIsLoading(false);
    });
  }, []);
  const handleInputChange = inputValue => {
    if (inputValue && inputValue.length >= 3) {
      setIsLoading(true);
      debouncedLoadOptions(inputValue, results => {
        setOptions(results);
        setIsLoading(false);
      });
    }
  };
  const classes = cn({
    'field-input-container': true,
    'field-error': errors,
    'cursor-not-allowed opacity-50': isDisabled,
    [className]: className
  });
  return <div id={id} className={classes}>
        {!hideTitle && title && <h5>{title}</h5>}

        {!hideLabel && <label htmlFor="genres-select">
            {label ? label : t('field.genres.label')}
          </label>}

        <Select filterOption={() => true} ref={ref} blurInputOnSelect={false} className="select" classNamePrefix="select" closeMenuOnSelect={false} isDisabled={isDisabled} isMulti isClearable inputId="genres-select" isLoading={isLoading} isOptionDisabled={checkIfOptionDisabled} onInputChange={handleInputChange} onChange={handleGenreChange} options={options} placeholder={t('field.genres.placeholder')} value={value} />
      </div>;
});
export default Genres;